export const METADATA = {
  site: {
    title: 'gini | Smart. Personal. Mindful Health.',
    description: 'Meet gini. A personal health &amp; nutritional assistant powered by your genetic code.',
  },
  account: {
    title: 'gini | Account',
    description: 'Manage your account and preferences. Upload your DNA data. Register your kit.',
  },
  import: {
    title: 'gini | Import',
    description: 'Upload your raw DNA data from popular DNA testing companies.',
  },
  reset: {
    title: 'gini | Reset',
    description: 'Reset your password securily and fast.',
  },
  register: {
    title: 'gini | Register',
    description: 'Register your kit. Learn about next steps.',
  },
  signup: {
    title: 'gini | Signup',
    description: 'Sign up to discover more about yourself and access your personal assistant.',
  },
  lens: {
    title: 'gini | Lens',
    description: 'Discover how foods and recipes can affect you based uniquely on you and your DNA',
  },
}
