export const colours = {
  TRANSPARENT: 'transparent',
  WHITE: '#FFFFFF',
  HIGHLIGHT_3: '#EFF3FF',
  HIGHLIGHT_2: '#5CB1FF',
  HIGHLIGHT_1: '#1F88EF',
  SECONDARY_1: '#AF307B',
  PRIMARY_2: '#3F26C3',
  PRIMARY_1: '#5719FF',
  GREY_3: '#EFEFEF',
  GREY_2: '#DDDDDD',
  GREY_2_O66: 'rgba(221,221,221,0.66)',
  GREY_1: '#C3C6D3',
  DARK_3: '#6D7288',
  DARK_2: '#52616F',
  DARK_1: '#39434B',
  SHADE_D10: 'rgba(0,0,0,0.1)',
  SHADE_D20: 'rgba(0,0,0,0.2)',
  SHADE_D25: 'rgba(0,0,0,0.25)',
  SHADE_D33: 'rgba(0,0,0,0.33)',
  SHADE_D50: 'rgba(0,0,0,0.5)',
  SHADE_D66: 'rgba(0,0,0,0.66)',
  SHADE_D75: 'rgba(0,0,0,0.75)',
  SHADE_D80: 'rgba(0,0,0,0.8)',
  SHADE_D90: 'rgba(0,0,0,0.9',
  SHADE_L10: 'rgba(256,256,256,0.1)',
  SHADE_L20: 'rgba(256,256,256,0.2)',
  SHADE_L25: 'rgba(256,256,256,0.25)',
  SHADE_L33: 'rgba(256,256,256,0.33)',
  SHADE_L50: 'rgba(256,256,256,0.5)',
  SHADE_L66: 'rgba(256,256,256,0.66)',
  SHADE_L75: 'rgba(256,256,256,0.75)',
  SHADE_L80: 'rgba(256,256,256,0.8)',
  SHADE_L90: 'rgba(256,256,256,0.9)',
}

export const ALL_COLOURS = {
  PRIMARY: '#2b0066',

  PRIMARY_L1: '#5600ff',
  PRIMARY_L2: '#7283ff',
  PRIMARY_L3: '#aab5ff',

  ACCENT: '#4acb00',

  NO_RISK_L1: '#707C88',
  NO_RISK_L2: '#979FA8',
  NO_RISK_L3: '#CACFD3',

  HEALTHY_L1: '#16b900',
  HEALTHY_L2: '#55CC45',
  HEALTHY_L3: '#95DF8B',
  
  DANGER_L1: '#f44300',
  DANGER_L2: '#F77645',
  DANGER_L3: '#FAA98B',

  CAUTION_L1: '#FFAB00',
  CAUTION_L2: '#FFC145',
  CAUTION_L3: '#FFD88B',

  RISK_0: '#f44300',
  RISK_1: '#f75b00',
  RISK_2: '#fa7000',
  RISK_3: '#fc7c00',
  RISK_4: '#fe9400',
  RISK_5: '#ffab00',
  RISK_6: '#d7b200',
  RISK_7: '#aab700',
  RISK_8: '#75b900',
  RISK_9: '#16b900',
  
  SHADE_D10: 'rgba(0,0,0,0.1)',
  SHADE_D20: 'rgba(0,0,0,0.2)',
  SHADE_D25: 'rgba(0,0,0,0.25)',
  SHADE_D33: 'rgba(0,0,0,0.33)',
  SHADE_D50: 'rgba(0,0,0,0.5)',
  SHADE_D66: 'rgba(0,0,0,0.66)',
  SHADE_D75: 'rgba(0,0,0,0.75)',
  SHADE_D80: 'rgba(0,0,0,0.8)',
  SHADE_D90: 'rgba(0,0,0,0.9)',

  SHADE_L10: 'rgba(256,256,256,0.1)',
  SHADE_L20: 'rgba(256,256,256,0.2)',
  SHADE_L25: 'rgba(256,256,256,0.25)',
  SHADE_L33: 'rgba(256,256,256,0.33)',
  SHADE_L50: 'rgba(256,256,256,0.5)',
  SHADE_L66: 'rgba(256,256,256,0.66)',
  SHADE_L75: 'rgba(256,256,256,0.75)',
  SHADE_L80: 'rgba(256,256,256,0.8)',
  SHADE_L90: 'rgba(256,256,256,0.9)',

  DARKER: '#1A2530',
  DARK: '#2C3E50',
  
  MID_0: '#52616F',
  MID_1: '#707C88',
  MID_2: '#8E98A1',
  MID_3: '#ACB3BA',
  MID_4: '#CACFD3',
  MID_5: '#E9EBED',
  
  LIGHT: '#F1F2F4',
  LIGHTER: '#F8F9FA',

  BLACK: '#000000',
  WHITE: '#FFFFFF',

  '23ANDME': '#D50F67',
  ANCESTRY: '#9CBE30',
}

export default ALL_COLOURS
