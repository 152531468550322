import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import { Search, UserSettings, Logout } from 'grommet-icons'
import { Anchor, Button, Box, Text, Image } from 'grommet'
import { signout } from '../actions/user'
import { colours } from '../util/colours'
import logo from '../images/gini-logo-blue.png'
const mapStateToProps = state => ({
  loggedIn: !!state.user.uid,
})

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch(signout())
})

const AppHeader = ({
  loggedIn,
  onLogout,
}) => (
  <Box
    direction='row'
    animation='fadeIn'
    elevation='xsmall'
    flex={false}
    align='center'
    justify='center'
    pad='small'
    style={{ height: '4rem' }}
  >
    <Box pad={{ horizontal: 'small' }}>
      <Anchor href='https://ginihealth.com' style={{ height: '100%' }}>
        <Image
          width={59}
          height={40}
          src={logo}
          fit='contain'
        />
      </Anchor>
    </Box>
    <Box
      basis='1/3'
      flex='grow'
      direction='row'
      justify='end'
    >
      {
        loggedIn && (
          <>
            <Link to='/lens'>
              <Box align="center" pad="small">
                <Search color={colours.DARK_1} />
                <Text size="small" color={colours.DARK_1}>Lens</Text>
              </Box>
            </Link>
            <Link to='/account'>
              <Box align="center" pad="small">
                <UserSettings color={colours.DARK_1} />
                <Text size="small" color={colours.DARK_1}>User</Text>
              </Box>
            </Link>
          </>
        )
      }
            {
        loggedIn && (
          <Button
            onClick={onLogout}
            plain
          >
            <Box align="center">
              <Logout color={colours.DARK_1} />
              <Text size="small">Sign out</Text>
            </Box>
          </Button>
        )
      }
    </Box>
  </Box>
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppHeader)