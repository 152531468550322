export const colors = {
  brand: '#2b0066',
  'accent-1': '#1F88EF',
  'accent-2': '#6D7288',
  'accent-3': '#A1A1A1',
  'accent-4': '#39434B',
  'focus': '#94A4FF',
}

export const main = {
  global: {
    drop: {
      extend: `
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
        overflow: hidden;
      `,
    },
    colors: colors,
    font: {
      name: `Roboto-Regular`,
      family: `'Roboto-Regular', Helvetica, Arial, sans-serif`,
      size: '1rem',
    },
  },
  anchor: {
    textDecoration: 'underline',
    color: {
      dark: '#7283ff',
      light: '#7283ff',
    },
  },
  formField: {
    label: {
      size: 'small',
      color: 'dark-6',
    },
  },
  heading: {
    weight: 400,
  },
}
