import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { Grommet, Box } from 'grommet'
import { main } from '../util/themes'
import colours from '../util/colours'

import AppHeader from './app-header'

import './app-layout.css'
import { METADATA } from '../util/siteMetadata'

const AppLayout = ({ page = 'site', children }) => (
  <>
    <Helmet
      title={METADATA[page].title}
      meta={[
        { name: 'description', content: METADATA[page].description },
      ]}
    >
      <html lang='en' />
    </Helmet>
    <Grommet theme={main} full>
      <Box
        fill='vertical'
      >
        <AppHeader />
        <Box
          flex='grow'
          background={colours.WHITE}
        >
          {children}
        </Box>
      </Box>
    </Grommet>
  </>
)

AppLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default AppLayout
